:root {
    --black: black;
    --white: white;
    --light:beige;
    --dark: gray;
    --accent: blue;

   
}
/* FONTS */

  @font-face {
    font-family: 'Barlow';
    src: url('../public/fonts/Barlow-Black.woff') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Barlow';
    src: url('../public/fonts/Barlow-Bold.woff') format('truetype'), url('../public/fonts/Barlow-ExtraBold.woff') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Barlow';
    src: url('../public/fonts/Barlow-Light.woff') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  } 
  @font-face {
    font-family: 'Barlow';
    src: url('../public/fonts/Barlow-Medium.woff') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Barlow';
    src: url('../public/fonts/Barlow-Regular.woff') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Barlow';
    src: url('../public/fonts/Barlow-SemiBold.woff') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  } 
  
  
    body {
        background-color: var(--background);
        font-family: Barlow, sans-serif;
        color: var(--black);
        font-size: 1rem;
        line-height: 1.5;
      }

    button{

      color: var(--black);
      border-color: var(--black);
    }  
      h1 {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 3rem;
        line-height: 4rem;
        font-weight: 900;
      }
      
      h2 {
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-size: 2.5rem;
        line-height: 3rem;
        font-weight: 900;
      }
      
      h3 {
        margin-top: 0rem;
        margin-bottom: 0rem;
        font-size: 2rem;
        line-height: 2.25rem;
        font-weight: 700;
      }
      
      h4 {
        margin-top: 0rem;
        margin-bottom: 0rem;
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 600;
      }
      h5 {
        margin-top: 0rem;
        margin-bottom: 0rem;
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 500;
      }
      p {
        margin-bottom: 0rem;
        font-size: 1.25rem;
      }
      
      ul {
        margin-top: 0rem;
        margin-bottom: 0rem;
        padding-left: 1rem;
        list-style-type: none
      }
      
      ol {
        margin-top: 0rem;
        margin-bottom: 0rem;
        padding-left: 1.5rem;  
      }
      
      li {
        margin-bottom: 0.25rem;
        text-decoration: none;
        }
      
      a {
        color: var(--black);
        text-decoration: none;
      }
      a:hover {
        color: var(--accent); 
      }
      
      a.button-icon, .button-icon {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 0.55rem 2.5rem;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        border-style: solid;
        border-width: 1px;
        border-color: var(--accent);
        border-radius: 6.25rem;
        background-color: var(--accent);
        box-shadow: 0 0 0 0 var(--accent), 7px 7px 33px 0 rgba(152, 134, 197, 0.35);
        -webkit-transition: border-color 100ms cubic-bezier(.23, 1, .32, 1), box-shadow 400ms cubic-bezier(.23, 1, .32, 1);
        transition: border-color 100ms cubic-bezier(.23, 1, .32, 1), box-shadow 400ms cubic-bezier(.23, 1, .32, 1);
        color: var(--white);
        font-size: 1.375rem;
        font-weight: 400;
        text-align: center;
        text-decoration: none;
      }
      


      .button-icon:hover {
        box-shadow: 0 0 0 4px var(--offwhite);
        /**background: var(--dark);
        border-color: var(--accent);
        color:var(--white);**/
        -webkit-filter: brightness(80%); 
        filter: brightness(80%);
      }
      
      
      .button-icon-spacer {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 0.5rem;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
      }
      /**
      .icon-button {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 1rem;
        height: 1rem;
        is this needed? 
      }
      **/
      
      .flex-group {
        display: flex;
      }

    .form-wrapper {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-right: 3.75rem;
    padding-left: 3.75rem;
    padding-top: 3.75rem;
    padding-bottom: 3rem;
    border-radius: 3.125rem;
    width: 100%;
    background-color: var(--dark);
    color: var(--white);
  }
  
  .form-wrapper-link{
    padding-top: 0.5rem;
     padding-bottom: 0.5rem;
    -webkit-transition: color 350ms cubic-bezier(.23, 1, .32, 1);
      transition: color 350ms cubic-bezier(.23, 1, .32, 1);
      color:var(--white);
      font-size: 1rem;
      font-weight: 400;
      text-decoration: none;
    
  }
      
  .form-wrapper-link:hover{
    color: var(--accent);
  }
  
  .navbar-toggler-icon{
    color: white;
    background-color: white;
    border-color: lightgreen;
  }

  .search-wrapper {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-right: 3.75rem;
    padding-left: 3.75rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 3.125rem;
    width: 75%;
    background-color: var(--dark);
    color: var(--white);
  }
  
  
  .header-wrapper {
   
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color:var(--white);
    color: var(--white);
  }
  .footer-wrapper {
   
    padding-top: 3.75rem;
    padding-bottom: .25rem;
    background-color:var(--dark);
    color: var(--white);
  }
  
  .footer-link{
    padding-top: 0.5rem;
     padding-bottom: 0.5rem;
    -webkit-transition: color 350ms cubic-bezier(.23, 1, .32, 1);
      transition: color 350ms cubic-bezier(.23, 1, .32, 1);
      color: var(--white);
      font-size: 1.25rem;
      font-weight: 400;
      text-decoration: none;
  }
  
    .footer-link:hover {
      color: var(--accent);
  }
  
  
  .footer-link-header{
    padding-top: 0.5rem;
     padding-bottom: 0.5rem;
    -webkit-transition: color 350ms cubic-bezier(.23, 1, .32, 1);
      transition: color 350ms cubic-bezier(.23, 1, .32, 1);
      color: var(--white);
      font-size: 1.25rem;
      font-weight: 400;
      text-decoration: none;
  }
  
  .vendor_contact-wrapper {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding-right: 3.75rem;
    padding-left: 3.75rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 3.125rem;
    width: 80%;
    background-color: var(--white);
    color: var(--black);
  }
  
  
  .vendor_contact{
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-size: 1.5rem;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 300;
    padding-right: 3rem;
    padding-left: 3rem;
    color: var(--black);
  }
  .vendor_contact_title{
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 2rem;
    text-decoration: none;
    font-size: 2.5rem;
    font-weight: 600;
    padding-right: 3rem;
    padding-left: 3rem;
    color: var(--black);
  }
  .vendor_contact_intro{
    padding-top: 1rem;
    padding-bottom: 1rem;  
    font-size: 1.5em;
    text-decoration: none;
   
    font-weight: 300;
    padding-right: 3rem;
    padding-left: 3rem;
    color: var(--black);
  }
  .vendor_contact_icon{
    padding-top: .5rem;
    padding-bottom: 0rem;
    font-size: 1.5em;
    text-decoration: none;
   
    font-weight: 300;
    padding-right: 3rem;
    padding-left: 3rem;
    color: var(--accent);
  }
  
  .vendor_contact-link{
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--white);
    
  }
  .vendor_contact-link:hover{
    color: var(--accent);
  }
  
  
  .nav-link{
  
    color: var(--black);
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 1.0rem;
  }
  .nav-link:hover{
    color: var(--accent);
  
  }
  
  .header-wrapper{
  
    text-decoration:none;
  }
  
  .header-link{
    color: (var(--black));
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 400;
    padding-right: 3rem;
    padding-left: 3rem;
    padding-top: 1rem;
  
  }
  
  .header-link:hover{
    color: var(--accent);
  }
  
  .card_link{
    color: var(--black);
  
  }
  .card_link:hover{
    color: var(--accent);
  }

  .card-list-item{
    font-size: 1.25rem;
    padding-left: .25rem;
  }
  .card_vendor-link{
    font-size: 1.25rem;
    
  }


  .footer_policy-link{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
   -webkit-transition: color 350ms cubic-bezier(.23, 1, .32, 1);
     transition: color 350ms cubic-bezier(.23, 1, .32, 1);
     color: var(--white);
     font-size: 1rem;
     font-weight: 200;
     text-decoration: none
  
  }
  .footer_policy-link:hover {
    color: var(--accent);
  }
  
  .footer_social-link {
   display: inline-block;
    height: 44px;
    padding: 0.625rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-transition: color 350ms cubic-bezier(.23, 1, .32, 1);
    transition: color 350ms cubic-bezier(.23, 1, .32, 1);
    color: var(--accent);
  }
  .footer_social-link:hover {
    color: var(--accent);
    -webkit-filter: brightness(80%); 
    filter: brightness(80%);
  }
    
  
  .grid-link {
    color: var(--black);
   }
   .grid-link:hover {
     color: var(--accent);
   }
  
  .social_list{
    display:inline;
  }

  .card-wrapper{
    width: 22rem;
    height: 35rem;
    border-radius: 2em;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    margin: 1.5rem;
  }
   
  
  .card-content{
  text-decoration:none;
  
  }
  
  .card-body{
    padding: 2rem;
  }
  
  
  .card-text{
    font-weight: 400;
    padding: 1rem;
  }
  
  .card-content:hover{
  color: var(--black);
  }
  
  .card-image{
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
  }
  
  .card-link-more{
    text-decoration: none;
    padding: .5rem;
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--accent);
  }
  
  .card-link-more:hover {
    color:var(--black);
  }
  
  .card-wrapper-horiz{
    width: 78rem;
    border-radius: 2em;
    text-align: center;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    margin: 1.5rem;
  }
  
  .card-image-horiz{
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    padding:0rem;
  }
  
  .client_logo-wrapper{
    display:inline;
    vertical-align: middle;
    }

  .client_logo_image{
    padding-top: 0rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  
  
  /**
  .wave-background {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    background-image: url('../images/wave.svg'), -webkit-gradient(linear, left top, left bottom, color-stop(18%, #fff), color-stop(26%, #e9e6f5));
    background-image: url('../images/wave.svg'), linear-gradient(180deg, #fff 18%, #e9e6f5 26%);
    background-position: 50% 100px, 0px 0px;
    background-size: 100%, auto;
    background-repeat: no-repeat, repeat;
    outline-offset: 0px;
    outline-style: none;
    outline-width: 0px;
    color: #fff;
  }
  
  .wave-background.large {
    background-color: #fff;
    background-image: url('../images/wave.svg'), -webkit-gradient(linear, left top, left bottom, color-stop(40%, #fff), color-stop(46%, #e9e6f5));
    background-image: url('../images/wave.svg'), linear-gradient(180deg, #fff 40%, #e9e6f5 46%);
    background-position: 50% 10px, 0px 0px;
    background-size: 100%, auto;
    background-repeat: no-repeat, repeat;
  }
  
  .wave-background.header {
    display: none;
    background-color: #fff;
    background-image: url('../images/wave.svg'), -webkit-gradient(linear, left top, left bottom, color-stop(30%, #fff), color-stop(32%, #e9e6f5));
    background-image: url('../images/wave.svg'), linear-gradient(180deg, #fff 30%, #e9e6f5 32%);
    background-position: 50% 40%, 0px 0px;
    background-size: 100%, auto;
    background-repeat: no-repeat, repeat;
  }
  
  .wave-background.top {
    display: block;
    background-image: url('../images/wave2.svg'), -webkit-gradient(linear, left top, left bottom, color-stop(3%, #fff), color-stop(5%, #e9e6f5));
    background-image: url('../images/wave2.svg'), linear-gradient(180deg, #fff 3%, #e9e6f5 5%);
    background-position: 50% 0px, 0px 0px;
    background-size: 100%, auto;
    background-repeat: no-repeat, repeat;
  }
  **/


  /** post type specific styles **/

  .card_skill-wrapper{
    width: 22rem;
    height: 20rem;
    border-radius: 2em;
    
    text-align: center;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
    margin: 1.5rem;

   }

   .card_skill-excerpt{
    text-align: left;
    height:13rem;
   }
  
   
   
  
   .skill_detail-wrapper{
    padding-top: 3.75rem;
   }

  /**post type project**/
  .card_project-wrapper{
    height:32rem;
  }

  .card_project-body{
      height:26rem;
  }  
  
  /**post type article**/
  .card_article-wrapper{
    height:36rem;
  }

  .card_article-body{
    height:14rem;
   
  }
    /**post type attraction**/
    .card_attraction-wrapper{
      height:42rem;
    }
  
    .card_attraction-body{
        height:18rem;
    }  