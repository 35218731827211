body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background-color: #c4c4c4;
}

.App-header {
  padding-top: 20px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
}

label {
  font-weight: bold;
  padding-left: 10px;
}

button {
  z-index: 2;
  cursor: pointer;
  
  background-color: #000;
  color: #fff;
  padding: 10px 0;
}

.cropped-image {
  height: 600px;
  width: auto;
}

.cropped-image-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

._coverImage-holder  {
  padding: 25px 40px;
  background-color:black;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}


.container {
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin-top: 25px; */
}

.crop-container {
  height: 600px;
  width: 600px;
}

.controls {
  display: flex;
  flex-direction: column;
  width: 600px;
  position: absolute;
  bottom: -15px;
}

